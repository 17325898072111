import React from 'react'
import { InlineMath, BlockMath } from 'react-katex';
import 'katex/dist/katex.min.css'; // Also import the KaTeX CSS

function Metric({ metricInfo,label,digits}){
    if (!metricInfo){
        return(
             <div className="metric">
            <span className="latex-tooltip" id="latex-metric1"></span>
            <div className="metric-name"></div>
            <div className="metric-value">Loading</div>
            <div className="metric-change" ><span className="change-arrow" ></span></div>
        </div>
    )
    }
    const equations={
        "er_nom":"\\mathbb{E}[R_{nominal}]=\\mathbb{E}[R]+\\pi_{breakeven}",
        "er":"\\mathbb{E}[R]=\\mathbb{E}[ERP]+r^f",
        "erp":"ERP=\\gamma \\sigma^2",
        "risk_aversion":"\\gamma=\\frac{\\frac{D}{P}-r^f+\\frac{\\sigma^2}{2}+g}{\\sigma^2}",
        "log_growth":"g=\\sqrt{\\left(\\frac{D}{P}-r^f+\\frac{\\sigma^2}{2}\\right)^2+2\\sigma^2(r^f-\\delta)}"
    }

    const symbol=metricInfo['symbol']
    const value=metricInfo['last']
    const prior=metricInfo['prior']
    const change=value-prior
    
    const formattedValue= symbol=='risk_aversion' ? (value).toFixed(digits) : (value*100).toFixed(digits)+'%'    
    const formattedChange= symbol=='risk_aversion' ? (change).toFixed(digits) :(change*100*100).toFixed(0)+' bps'

    const direction = change >= 0 ? '↑' : '↓';
    let color = change >= 0 ? 'red' : 'green'; // Adjust as needed
    if (symbol === 'log_growth') {
        color = change >= 0 ? 'green' : 'red'; // For log growth
    }

    return (
        <div className="metric">
            <span className="latex-tooltip" id="latex-metric1"><InlineMath math={equations[symbol]==null?'':equations[symbol]} /></span>
            <div className="metric-name">{label}</div>
            <div className="metric-value">{formattedValue}</div>
            <div className="metric-change" style={{color:color}}>{formattedChange}<span className="change-arrow" >{direction}</span></div>
        </div>

    );
}
export default Metric;
