import React from 'react';
import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

var crosshairPlugin = {
  id: 'crosshair',
  afterDraw: function(chart, args, options) {
    if (chart.tooltip && chart.tooltip._active && chart.tooltip._active.length) {
      var ctx = chart.ctx;
      var x = chart.tooltip._active[0].element.x;
      var topY = chart.scales.y.top;
      var bottomY = chart.scales.y.bottom;

      // draw line
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, topY);
      ctx.lineTo(x, bottomY);
      ctx.lineWidth = 1;
      ctx.strokeStyle = 'rgba(0, 0, 0, 0.5)';
      ctx.stroke();
      ctx.restore();
    }
  }
};

function ChartComponent({chartinfo,chartName, isPercentage,isLog}) {
//  console.log(chartinfo)
 
  if (!chartinfo || !chartinfo.datasets) {
   return <div>Loading data...</div>;
  }

  // Reverse the dataset order for the tooltip
  //const modifiedChartData = chartinfo.chartData.reverse();
    const modifiedChartData=chartinfo

   // Register the plugin
  Chart.register(crosshairPlugin);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1, 
    crosshair: {
      line: {
        color: '#F66', 
        width: 1 
      }
    },
    scales: {
      y: {
        stacked: false,
        type:isLog?'logarithmic':'linear',
        ticks: {
          callback: function(value) {
            return isPercentage ? `${(value * 100).toFixed(1)}%` : value.toFixed(2);
          }
        }
      }
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      tooltip: {
        enabled: true,
        mode: 'index',
        intersect: false,
        callbacks: {
            label: function(context) {
              let label = context.dataset.label || '';
              if (label) {
                label += ': ';
              }
              // Convert the number to a percentage string with one decimal place
              const value = context.raw; // Assuming your data is in a format like 0.123 for 12.3%
              if(isPercentage){
                label += (value * 100).toFixed(2) + '%';
              }
              else{

                label+=value.toFixed(2)
              }
              return label;
            }, //closes label
            labelColor: function(context) {
              return {
                borderColor: 'transparent',
                backgroundColor: context.dataset.borderColor,
                borderWidth: 2,
                borderDash: [2, 2],
                borderRadius: 2,
              };
            },
          }
      },
      legend: {
        labels: {
          usePointStyle: true,
          pointStyle: 'line',
          padding: 20,
        }
      }
    }
  };
  console.log("ChartComponent")
  return <Line data={modifiedChartData} options={options}/>;
  } 



function Mychart({chartinfo, isPercentage,isLog=false,title,subtitle,notes}){
    const isDataAvailable = chartinfo && chartinfo.datasets;

    return (
        <>
        <div className="chart-header">
            <h1>{title}</h1>
            <h2>{subtitle}</h2>
        </div>
        <div className="chart-container">
          <ChartComponent chartinfo={chartinfo} chartName={title} isPercentage={isPercentage} isLog={isLog} />
        
        </div>
        <footer>
            <div className="notes">
                {notes}            </div>
        </footer>
        </>
    );
}
export default Mychart;
