import React, { useEffect, useState } from 'react';
import Metric from './Metric';
import Mychart from './Mychart';
import { Link } from 'react-router-dom';
import './theory_styles.css';  


 const halfkellyratio=0.5;
 const sixtyfourtyratio=0.6/1.59;
 const hundredpercentratio=1.0/1.59;   
  

function parseAllocation(csv) {
  const lines = csv.split('\n');
  const result = [];
  const headers = lines[0].split(',');
  //console.log(headers);
  const indexRa = headers.indexOf('risk_aversion');
  const indexErp = headers.indexOf('erp');


  lines.slice(1).forEach(line => {
    const cols = line.split(',');
    const date = cols[1]; // Date
    result.push({
      date,
      data: [parseFloat(cols[indexRa]), hundredpercentratio*parseFloat(cols[indexRa]),halfkellyratio*parseFloat(cols[indexRa]),sixtyfourtyratio*parseFloat(cols[indexRa])]
    });
  });
  const colors = ['black','navy', 'blue','lightblue']; // Colors for the datasets

  return { //this will get passed to createChart
    labels: result.map(row => row.date),
    datasets: ['Kelly','100% average','Half Kelly','60/40 average'].map((header, index) => ({
      label:['Kelly','100% average','Half Kelly','60/40 average'][index],
      borderColor: colors[index],
      data: result.map(row => row.data[index]),
      fill: false, // Change to false for non-stacked line chart
      pointRadius: 0, // Hides the points on the line
      tension: 0.1,
    })),
  };
} 


function parseReturns(csv) {
  const lines = csv.split('\n');

  const result = [];
  const headers = lines[0].split(',');
  const indexRa = headers.indexOf('risk_aversion');
  const indexErp = headers.indexOf('erp');
  const indexRf = headers.indexOf('return1mo');
  const indexRmx = headers.indexOf('excess_return');
  var trindex=[1,1,1,1,1]
  var priorallocation=[1,1,1,1,1]

  lines.slice(1).forEach(line => {
    const cols = line.split(',');
    const date = cols[1]; // Date
    const rf=parseFloat(cols[indexRf]);
    const rmx=parseFloat(cols[indexRmx]);
    const data=[];
    const returns=[];
  
    for (let i=0; i<5;i++){
    	const curret=((1-priorallocation[i])*rf+(priorallocation[i]*rmx))

    	data[i]=isNaN(curret)?trindex[i]:(trindex[i]*(1+curret))
    }
    result.push({
      date,
      data: data
    });
    priorallocation=[parseFloat(cols[indexRa]), hundredpercentratio*parseFloat(cols[indexRa]),halfkellyratio*parseFloat(cols[indexRa]),sixtyfourtyratio*parseFloat(cols[indexRa]),1];
    trindex=data
  });
  const colors = ['black','navy', 'blue','lightblue','red']; // Colors for the datasets

  return { //this will get passed to createChart
    labels: result.map(row => row.date),
    datasets: ['Kelly','100% average','Half Kelly','60/40 average','S&P'].map((header, index) => ({
      label:['Kelly','100% average','Half Kelly','60/40 average','S&P'][index],
      borderColor: colors[index],
      data: result.map(row => row.data[index]),
      fill: false, // Change to false for non-stacked line chart
      pointRadius: 0, // Hides the points on the line
      tension: 0.1,
    })),
  };

}



function OptimalAllocation(){
	const [allocationData, setallocationData] = useState(null);
	const [returnData, setReturnData] = useState(null);

	const [currentData, setCurrentData] = useState(null);

    useEffect(() => {
      fetch('/historical_values.csv')
        .then(response => response.text())
        .then(csv => {
            const raParsedData = parseAllocation(csv);
            const returnParsedData=parseReturns(csv)
         	setallocationData(raParsedData);
			setReturnData(returnParsedData);
			console.log(returnParsedData)
        });
    },[]);
     useEffect(() => {
      fetch('/erp_latest.txt')
        .then(response => response.json())
        .then(data=>setCurrentData(data))
        .catch(error => console.error('Error:', error));
    }, []);

    const risk_aversion=currentData?currentData['risk_aversion']:null;
    const kelly={
    	'change':risk_aversion==null?null:risk_aversion.change,
    	'last':risk_aversion==null?null:risk_aversion.last,
    	'last_time':risk_aversion==null?null:risk_aversion.last_time,
    	'prior':risk_aversion==null?null:risk_aversion.prior,
    	'symbol':'kelly'
    }
    const hundredpercent={
     	'change':risk_aversion==null?null:parseFloat(risk_aversion.change)*hundredpercentratio,
     	'last':risk_aversion==null?null:parseFloat(risk_aversion.last)*hundredpercentratio,
     	'last_time':risk_aversion==null?null:risk_aversion.last_time,
     	'prior':risk_aversion==null?null:parseFloat(risk_aversion.prior)*hundredpercentratio,
     	'symbol':'hundredpercent'
    }
    const halfkelly={
     	'change':risk_aversion==null?null:parseFloat(risk_aversion.change)*halfkellyratio,
     	'last':risk_aversion==null?null:parseFloat(risk_aversion.last)*halfkellyratio,
     	'last_time':risk_aversion==null?null:risk_aversion.last_time,
     	'prior':risk_aversion==null?null:parseFloat(risk_aversion.prior)*halfkellyratio,
     	'symbol':'halfkelly'
    }

    const sixtyfourty={
     	'change':risk_aversion==null?null:parseFloat(risk_aversion.change)*sixtyfourtyratio,
     	'last':risk_aversion==null?null:parseFloat(risk_aversion.last)*sixtyfourtyratio,
     	'last_time':risk_aversion==null?null:risk_aversion.last_time,
     	'prior':risk_aversion==null?null:parseFloat(risk_aversion.prior)*sixtyfourtyratio,
     	'symbol':'sixtyfourty'
    }




 	return (
 		<>
 		        <div className="metrics-wrapper">
            <div className="metrics-container">

	         <Metric metricInfo={kelly} label='Kelly'/>
	         <Metric metricInfo={hundredpercent} label='100% average'/>
  	         <Metric metricInfo={halfkelly} label='Half Kelly'/>
  	         <Metric metricInfo={sixtyfourty} label='60/40 average'/>

	        </div>
             <div id="lastUpdateTime">Last updated:{kelly.last_time} </div>
	      </div>

	    <div className="theory-container">


	      <h1>Optimal Allocation to Equities</h1>
	      <p>The optimal allocation to equities given an expected return and volatility depends on an individual's risk aversion. This page highlights the optimal allocation (under an the assumptions covered in the theory section) for individuals with different risk aversions. The available assets are assumed to be only aggregate equity market exposure and risk free bonds </p> 
		<p><b>Kelly</b> is an investor that has a risk aversion of 1 or, equivalently, a utility function equal to log of 	consumption(i.e., maximizes the median outcome according to the Kelly criterion).</p> 
		<p><b>100% average</b> is an investor that seeks to have, on average over the long term, 100% of portfolio allocated to equities.</p> 
		<p><b>Half Kelly</b> is an investor that follows the rule of thumb to have half the exposure recommended by the Kelly crietrion</p> 
		<p><b>60/40 average</b> is an investor that seeks to have, on average over the long term, 60% of portfolio allocated to equities and 40% to bonds.</p> 
		<br/>
		<br/>
        <Mychart title="Optimal Equity Allocation" chartinfo={allocationData} isPercentage={true} subtitle="% of Portfolio" notes="Assumes iid lognormal consumption growth equal to dividend growth, with δ=1.0% and σ=17%, using dividend yield from the S&P 500. 100% average and 60/40 average based on average risk aversion of 1.6"/>
        <br/>
		<br/>
        <Mychart title="Total Return Index" chartinfo={returnData} isPercentage={false} isLog={true} subtitle="Index" notes="Total return for each strategy, with the allocation to equities defined above and the remainder invested in 1 month treasuries. For allocations greater than 100%, assumes borrow rate at 1 month treasury rate. Assumes iid lognormal consumption growth equal to dividend growth, with δ=1.0% and σ=17%, using dividend yield from the S&P 500. 100% average and 60/40 average based on average risk aversion of 1.6"/>
        


        </div>
        </>



		);
}
export default OptimalAllocation;
