import React from 'react';
import { InlineMath, BlockMath } from 'react-katex';
import { Link } from 'react-router-dom';

import 'katex/dist/katex.min.css'; // Also import the KaTeX CSS
import './style.css';  
import './theory_styles.css';  
import delta_bound from './images/delta_bound.png';
import log_growth_comparison from './images/log_growth_comparison.png'
import risk_aversion from './images/risk_aversion.png'
import erp_compare from './images/erp_compare.png'

const Theory = () => {
  const title = "Theory";


  // You can define other sections similarly, and even make them stateful or dynamic if needed
  const introduction = `Expected log consumption growth, risk aversion and the market implied equity risk premium can be derived from first principles (with a basic model of the economy) using dividend yield and the risk free rate, requiring only assumptions on volatility and pure time preference, resulting in the following equations:`;

  // The equations will be represented as strings for MathJax to render
  const equations = [
    `g = \\sqrt{\\left(\\frac{D}{P} - r^f + \\frac{\\sigma^2}{2}\\right)^2 + 2\\sigma^2\\left(r^f - \\delta\\right)}`,
    `\\gamma = \\frac{\\frac{D}{P} - r^f + \\frac{\\sigma^2}{2} + \\sqrt{\\left(\\frac{D}{P} - r^f + \\frac{\\sigma^2}{2}\\right)^2 + 2\\sigma^2(r^f - \\delta)}}{\\sigma^2}`,
    `E(R_m) - r^f = \\frac{D}{P} - r^f + \\frac{\\sigma^2}{2} + \\sqrt{\\left(\\frac{D}{P} - r^f + \\frac{\\sigma^2}{2}\\right)^2 + 2\\sigma^2(r^f - \\delta)}`
  ];

  return (
    <div className="theory-container">
        <h1>{title}</h1>
        <h3>TL;DR</h3>
        <p>{introduction}</p>
        
        {equations.map((equation, index) => (
          <div key={index}>
            <BlockMath math={equation} />
          </div>
        ))}

        <p>Where <InlineMath math={"\\frac{D}{P}"}/> is the dividend yield on the market and <InlineMath math={"r^f"}/> is the long term real risk free rate, with recommended assumptions of <InlineMath math={"\\sigma=17\\%"}/> and <InlineMath math={"\\delta=1.0\\%"}/>.</p>

        <p>Calculated current and historical values implied by model available in <Link to="/historicals" >Market-Implied Statistics</Link> section.</p>


        {/* MODEL SETUP */}
        <h3>Model Setup</h3>
        
        <h2>Consumption Process</h2>
        <p>Consider an endowment economy (all consumption each period is derived from dividends, all of which are consumed).
        The logarithm of consumption has constant expected growth and constant volatility:</p>
        <BlockMath math={"dc_t = g \\, dt + \\sigma dW_t"} />
        <p>Where  <InlineMath math={"c_t"}/> is the logarithm of consumption at time  <InlineMath math={"t"}/>,  <InlineMath math={"g"}/> is the expected growth rate of log consumption, <InlineMath math={"\\sigma"}/> is the instantaneous volatility of consumption, and <InlineMath math={"dW_t"}/> is the random change at time <InlineMath math={"t"}/>.</p>
        
        <h2> Utility </h2>
        <p>Utility from a given period's consumption for a representative agent is:</p>
        <BlockMath math={"U(C_t)=\\frac{C_t^{1-\\gamma}}{1-\\gamma}"}/>
  
        <p>Where <InlineMath math={"C_t"}/> is consumption at period <InlineMath math={"t"}/> and <InlineMath math={"\\gamma"}/> is the coefficient of risk aversion.</p>

        <p>Marginal utility of consumption is given by:</p>
        <BlockMath math={"U^{\\prime}(C_t)=C_t^{-\\gamma}"}/>
        <p>Let <InlineMath math={"\\Lambda_{t+s}=e^{-\\delta s}U^{\\prime}(C_{t+s})"}/> represent the present utility at time <InlineMath math={"t"}/> of a marginal unit of consumption  <InlineMath math={"s"}/> periods in the future (where <InlineMath math={"\\delta"}/> is pure time preference). In equilibrium, the utility the representative investor forgoes by paying price <InlineMath math={"P_t"}/> must be offset by the expected utility from the consumption of the future dividend. The current price of a risky future dividend follows:</p>

         <BlockMath math={"P_t \\Lambda_t=E_t(\\Lambda_{t+s} D_{t+s})"}/>

        <h2> Stock Market </h2>
        <p>The stock market pays a continuous dividend <InlineMath math={"D_t=C_t"}/>, and trades at <InlineMath math={"P_t"}/>, the expected present value of all future dividends.</p>
          <BlockMath math={"P_t \\Lambda_t=\\int_{s=0}^{\\infty}E_t(\\Lambda_{t+s} D_{t+s})ds"}/>

         <p>This setup is enough to create closed-form equations for calculating expected growth, risk aversion and the equity risk premium in terms of volatility, pure time preference and market prices.</p>

        <h3>Equations for market-implied values</h3>
        <p>Solving the model gives equations for unobservable quantities in terms of dividend yield <InlineMath math={"\\frac{D}{P}"}/>, real risk free rate <InlineMath math={"r^f"}/>, volatility <InlineMath math={"\\sigma"}/> and time preference <InlineMath math={"\\delta"}/> (all derivations in the appendix). In this model, these quantities are all constant over time. </p>

        <h2>Growth</h2>
          <BlockMath math={"g = \\sqrt{\\left(\\frac{D}{P} - r^f + \\frac{\\sigma^2}{2}\\right)^2 + 2\\sigma^2\\left(r^f - \\delta\\right)}"}/> 
        
        <h2>Risk Aversion</h2>
        <BlockMath math={"\\gamma = \\frac{\\frac{D}{P} - r^f + \\frac{\\sigma^2}{2} + \\sqrt{\\left(\\frac{D}{P} - r^f + \\frac{\\sigma^2}{2}\\right)^2 + 2\\sigma^2(r^f - \\delta)}}{\\sigma^2}"}/>

        <h2>Equity Risk Premium</h2>
        <BlockMath math={"E(R_m) - r^f = \\frac{D}{P} - r^f + \\frac{\\sigma^2}{2} + \\sqrt{\\left(\\frac{D}{P} - r^f + \\frac{\\sigma^2}{2}\\right)^2 + 2\\sigma^2(r^f - \\delta)}"}/>

        <h2>Upper and Lower Bound on <InlineMath math={"\\delta"}/></h2>
        <p>The model places a restriction on potential values of <InlineMath math={"\\delta"}/>:</p>
        <BlockMath math={"0\\leq\\delta<r^f+\\frac{max\\left(\\frac{D}{P}-r^f+\\frac{\\sigma^2}{2},0\\right)^2}{2\\sigma^2}"}/>
        

        <h3>Estimates of Values</h3>
        <p>This section explores the values implied by the model over time, using the S&P 500 as a proxy for the stock market. While the model implies that quantities are constant over time, in reality the dividend yield and risk free rate vary. This section calculates the values implied by the model at each point in time using the contemporaneous values for the real risk free rate from 30 year Treasury Inflation Protected Securities (TIPS), and the dividend yield of the S&P 500.</p>
        <p>Calculating the values requires an assumption on <InlineMath math={"\\sigma"}/>, the volatility of returns, and <InlineMath math={"\\delta"}/>, the pure time preference. I use a value of <InlineMath math={"17\\%"}/> for <InlineMath math={"\\sigma"}/> based on historical volatility of the S&P 500, and a value of <InlineMath math={"1.0\\%"}/> for pure time preference, based on economics literature and guidance used by public policy makers, which is consistently below the upper bound implied by the model.</p>

        <table>
    <caption>Model implied average values compared to external estimates
    <div class='subcaption'>2004-2024 with <InlineMath math={"\\delta"}/> of 1.0% and <InlineMath math={"\\sigma"}/> of 17%</div>
    </caption>
    <thead>
        <tr>
            <th>Statistic</th>
            <th>Model Average</th>
            <th>Reference Value</th>
            <th>Reference Source</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>Expected Growth <InlineMath math={"g"}/></td>
            <td>2.42%</td>
            <td>2.20%</td>
            <td>Average Longer Run FOMC Projections for the Growth Rate of GDP, Range, Midpoint</td>
        </tr>
        <tr>
            <td>Risk Aversion <InlineMath math={"\\gamma"}/></td>
            <td>1.59</td>
            <td>1.61</td>
            <td>Acland and Greenberg average of 1,711 estimates from 158 studies</td>
        </tr>
        <tr>
            <td>Equity Risk Premium</td>
            <td>4.60%</td>
            <td>5.40%</td>
            <td>Average US Implied ERP 2008-2024 Damodaran</td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td>4.40%</td>
            <td>US Realized ERP 1900-2024 from Credit Suisse Investment Returns Yearbook</td>
        </tr>
    </tbody>
</table>
<p>The model provides simultaneous estimates for risk aversion, expected growth of log consumption, and the equity risk premium at each point in time, each of which closely matches external and independent estimates for those quantities. Specifically, the model outputs values of risk aversion consistent with the economic literature, expected growth in line with historical Federal Reserve estimates and an equity risk premium that tracks closely over time the implied equity risk premium published by Aswath Damodaron. The strength of the model is a parsimonious output of all these quantities requiring minimal judgment on input: only market prices and an assumed value for volatility and time preference.</p>
<p>The subsections below detail the assumptions and estimates for variables.</p>

<h2>Assumptions for <InlineMath math={"\\sigma"}/> and <InlineMath math={"\\delta"}/></h2>
<p>The only "calibration" required for the model (i.e. values not directly from market prices) are volatility <InlineMath math={"\\sigma"}/> and pure time preference <InlineMath math={"\\delta"}/>. To estimate the values of growth, risk aversion and the equity risk premium, I use constant values of <InlineMath math={"17\\%"}/> for <InlineMath math={"\\sigma"}/> and <InlineMath math={"1.0\\%"}/> for <InlineMath math={"\\delta"}/>. </p>

<h4>Assumptions on <InlineMath math={"\\sigma"}/></h4>
The model requires as an input long term annualized volatility. The model does not distinguish between consumption, dividend and return volatility, which can differ significantly. I use return volatility, as it is the most directly observable and consistent over time. Aggregate consumption statistics must inevitably rely on estimates and smoothing, making direct volatility inference less reliable. Dividends can be impacted by change in dividend policy between dividends and buybacks, and are paid infrequently relative to returns. Returns are directly observable and have a long history of consistent volatility to use.  

To be consistent with dividend yield and risk free rate calculations, I use the historical S&P 500 volatility. While the precise value of volatility can differ depending on choice of time period, excess vs. gross returns, and real vs. nominal returns, all values are near <InlineMath math={"17\\%"}/>, as shown in the table below.

<table style={{ width: '100%', borderCollapse: 'collapse' }}>
    <caption style={{ marginBottom: '10px' }}>
        Annual Equity Volatility
    </caption>
    <thead>
        <tr>
            <th style={{ textAlign: 'left' }}>Time period</th>
            <th style={{ textAlign: 'center' }}>Volatility of nominal returns</th>
            <th style={{ textAlign: 'center' }}>Volatility of real returns</th>
            <th style={{ textAlign: 'center' }}>Volatility of excess returns</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>1944-1984</td>
            <td style={{ textAlign: 'center' }}>16.8</td>
            <td style={{ textAlign: 'center' }}>18.7</td>
            <td style={{ textAlign: 'center' }}>17.8</td>
        </tr>

        <tr>
            <td>1984-2024</td>
            <td style={{ textAlign: 'center' }}>16.1</td>
            <td style={{ textAlign: 'center' }}>16.1</td>
            <td style={{ textAlign: 'center' }}>16.0</td>
        </tr>
        <tr>
            <td>1944-2024</td>
            <td style={{ textAlign: 'center' }}>16.3</td>
            <td style={{ textAlign: 'center' }}>17.4</td>
            <td style={{ textAlign: 'center' }}>16.8</td>
        </tr>
    </tbody>
</table>

<h4>Assumptions on <InlineMath math={"\\delta"}/></h4>
<p><InlineMath math={"\\delta"}/> is the pure time preference, or the utility discount rate: the amount by which utility at the present is preferred over utility in the future. I assume a value of <InlineMath math={"1.0\\%"}/> for <InlineMath math={"\\delta"}/> in this paper, driven by a combination of empirical estimates, government guidance, survey responses from economists, and upper bound implied by this paper's model.</p>

<p><InlineMath math={"\\delta"}/> is of particular importance in welfare economics, in which policymakers must trade off welfare of current people for that of future people, resulting in discussion on the appropriate pure time preference rate. Derek Parfit (Parfit 1984) argued that, from a normative perspective, policymakers should assume a value of zero. Arrow and others have recommended a value of <InlineMath math={"1.0\\%"}/>  (Arrow 1995). The "Green Book" policy guidance document used to inform tradeoffs in the United Kingdom recommends a value for pure time preference of <InlineMath math={"1.5\\%"}/>  (comprised of <InlineMath math={"0.5\\%"}/> time factor and <InlineMath math={"1.0\\%"}/> to account for catastrophic risk).</p>

<p>ADB(2007) Compiled a list of estimates of pure time preference from 13 studies, shown below. Most of the values are close to <InlineMath math={"1.0\\%"}/>.  </p>

<table style={{ width: '100%', borderCollapse: 'collapse' }}>
    <caption style={{ marginBottom: '10px' }}>Summary of Empirical Estimates of <InlineMath math={"\\delta"}/> and Theoretical Bases from ADB (2007)</caption>
    <thead>
        <tr>
            <th style={{ width: '25%' }}>Source</th>
            <th style={{ width: '20%' }}>Estimate (Annual value)</th>
            <th style={{ width: '55%' }}>Theoretical Basis</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>Scott (1977)</td>
            <td>1.5%</td>
            <td>Component reflecting myopia is 0.5%, and that reflecting the changing life chance due to the risk of total destruction of a society is 1.0%</td>
        </tr>
        <tr>
            <td>Kula (1985)</td>
            <td>2.2%</td>
            <td>Reflecting average annual survival probability in the UK during 1900 to 1975</td>
        </tr>
        <tr>
            <td>Kula (1987)</td>
            <td>1.2%</td>
            <td>Reflecting average annual probability of death in the UK in 1975</td>
        </tr>
        <tr>
            <td>Scott (1989)</td>
            <td>1.3%</td>
            <td>Component reflecting myopia is 0.3%, and that reflecting the changing life chance due to the risk of total destruction of a society is 1.0%</td>
        </tr>
        <tr>
            <td>Newbery (1992)</td>
            <td>1.0%</td>
            <td>Perceived risk of the end of mankind in 100 years</td>
        </tr>
        <tr>
            <td>DICE model (Nordhaus 1993)</td>
            <td>3% per year</td>
            <td>Utility discount rate reflecting pure social time preference, determined by calibrating the DICE model to match actual data</td>
        </tr>
        <tr>
            <td>Pearce and Ulph (1995)</td>
            <td>1.1%</td>
            <td>Reflecting the average annual probability of death in the UK in 1991</td>
        </tr>
        <tr>
            <td>Arrow (1995)</td>
            <td>1%</td>
            <td>Utility discount rate reflecting pure social time preference, and matching the observed savings behavior</td>
        </tr>
        <tr>
            <td>OXERA (2002)</td>
            <td>Myopia = 0-0.5% Risk of death = 1.1% with a projected change in the near future to 1.0%</td>
            <td>Based on previous studies and projected and recent average annual death rates in the UK</td>
        </tr>
        <tr>
            <td>Evans and Sezer (2004)</td>
            <td>1.0–1.5%</td>
            <td>1% for EU countries and 1.5% for non-EU countries, reflecting catastrophe risks</td>
        </tr>
        <tr>
            <td>Kula (2004)</td>
            <td>1.3%</td>
            <td>Reflecting the average annual death rate in India during 1965–1995</td>
        </tr>
        <tr>
            <td>Evans (2006)</td>
            <td>1%</td>
            <td>Based on the approximate average annual death rate in 2002–2004 in 15 countries of the European Union</td>
        </tr>
        <tr>
            <td>Stern Review (2006)</td>
            <td>0.1%</td>
            <td>Probability of human race extinction per year</td>
        </tr>
    </tbody>
</table>
<p>Trying to understand consensus, the authors of Discounting Disentangled surveyed 180 economists on their views on the correct value to use. The average survey response estimated that pure time preference was <InlineMath math={"1.1\\%"}/>. The table below summarizes their responses.</p>
<table style={{ width: '100%', borderCollapse: 'collapse' }}>
    <caption style={{ marginBottom: '10px' }}>
        <InlineMath math={"\\delta"}/> estimate survey responses from "Discounting Disentangled". N=180.
    </caption>
    <thead>
        <tr>
            <th style={{ textAlign: 'left' }}>Statistic</th>
            <th style={{ textAlign: 'center' }}>Value</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>Mean</td>
            <td style={{ textAlign: 'center' }}>1.10%</td>
        </tr>
        <tr>
            <td>Standard Deviation</td>
            <td style={{ textAlign: 'center' }}>1.47%</td>
        </tr>
        <tr>
            <td>Minimum</td>
            <td style={{ textAlign: 'center' }}>0.00%</td>
        </tr>
        <tr>
            <td>Mode</td>
            <td style={{ textAlign: 'center' }}>0.00%</td>
        </tr>
        <tr>
            <td>Median</td>
            <td style={{ textAlign: 'center' }}>0.50%</td>
        </tr>
        <tr>
            <td>Maximum</td>
            <td style={{ textAlign: 'center' }}>8.00%</td>
        </tr>
    </tbody>
</table>
<p>Finally, the model provides an upper bound on <InlineMath math={"\\delta"}/> at each moment in time, using volatility and market prices as an input. The figure below provides the upper bound on <InlineMath math={"\\delta"}/> over time. Using <InlineMath math={"\\sigma"}/> of <InlineMath math={"17\\%"}/>, the minimum market-implied value of the upper bound over the time period 2004-2024 using this model is <InlineMath math={"1.26\\%"}/>.</p>

<h4 style={{textAlign: 'center'}}>Figure 1: <InlineMath math={"\\delta"}/> upper bound over time</h4>

<img src={delta_bound} alt="delta bounds" style={{ width: '100%'}}/>
<p>I use a value of <InlineMath math={"1.0\\%"}/> for the assumption on <InlineMath math={"\\delta"}/>, following the research and consistently below the upper bound of the model at all points of time measured.</p>

<h2>Estimated values of <InlineMath math={"g"}/>, <InlineMath math={"\\gamma"}/> and the equity risk premium</h2>
<p>This section uses the the assumptions of <InlineMath math={"\\delta"}/> and <InlineMath math={"\\sigma"}/> to calculate the values of the other quantities at each point in time.</p>
<h4>Estimate of expected growth of log consumption, <InlineMath math={"g"}/></h4>
<p>The figure and table below provides the expected growth of log consumption implied by the model over time. The average value implied by the model during the period 2004-2024 is <InlineMath math={"2.5\%"}/></p>

<p>Many estimates exist for expected economic growth. The Federal Reserve has published Real GDP growth estimates since 2009. The figure and chart below show show the model-implied expected growth of log consumption, along with contemporaneous estimates of GDP the Federal Reserve (high, midpoint and low estimates).  The model-implied growth is highly similar to the Federal Reserve's estimates, being <InlineMath math={"0.2\%"}/> higher on average during the period than the midpoint estimate (<InlineMath math={"2.4\%"}/> model-implied vs. <InlineMath math={"2.2\%"}/> estimate).</p>

<h4 style={{textAlign: 'center'}}>Figure 2: Model implied expected growth vs. Federal Reserve Forecast</h4>

<img src={log_growth_comparison} alt="log growth comparison" style={{ width: '100%'}}/>

<table style={{ width: '100%', borderCollapse: 'collapse' }}>
    <caption style={{ marginBottom: '10px' }}>
        Model implied expected long term growth, compared with estimates from the Federal Reserve. 
        <div class='subcaption'>Model assumes &#963; at 17%, using S&amp;P500 dividend yield and 30 year TIPS. Federal Reserve Midpoint represents "Longer Run FOMC Summary of Economic Projections for the Growth Rate of Real Gross Domestic Product, Range, Midpoint"</div>
    </caption>
    <thead>
        <tr>
            <th style={{ textAlign: 'left' }}>Statistic</th>
            <th style={{ textAlign: 'center' }}>Model (2009-2024)</th>
            <th style={{ textAlign: 'center' }}>Federal Reserve Midpoint</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>Mean</td>
            <td style={{ textAlign: 'center' }}>2.42%</td>
            <td style={{ textAlign: 'center' }}>2.21%</td>
        </tr>
        <tr>
            <td>Standard Deviation</td>
            <td style={{ textAlign: 'center' }}>0.42%</td>
            <td style={{ textAlign: 'center' }}>0.32%</td>
        </tr>
        <tr>
            <td>Minimum</td>
            <td style={{ textAlign: 'center' }}>1.22%</td>
            <td style={{ textAlign: 'center' }}>1.85%</td>
        </tr>
        <tr>
            <td>25th Percentile</td>
            <td style={{ textAlign: 'center' }}>2.31%</td>
            <td style={{ textAlign: 'center' }}>1.90%</td>
        </tr>
        <tr>
            <td>Median</td>
            <td style={{ textAlign: 'center' }}>2.45%</td>
            <td style={{ textAlign: 'center' }}>2.05%</td>
        </tr>
        <tr>
            <td>75th Percentile</td>
            <td style={{ textAlign: 'center' }}>2.59%</td>
            <td style={{ textAlign: 'center' }}>2.60%</td>
        </tr>
        <tr>
            <td>Maximum</td>
            <td style={{ textAlign: 'center' }}>4.26%</td>
            <td style={{ textAlign: 'center' }}>2.70%</td>
        </tr>
    </tbody>
</table>
<h4>Estimated values of risk aversion coefficient, <InlineMath math={"\\gamma"}/></h4>
<p> The risk aversion coefficient is a fundamental quantity in economics. It is the elasticity of marginal utility, and in power utility is the reciprocal of the elasticity of intertemporal substitution.</p>

<p>The risk aversion coefficients implied by the model averages 1.6 from 2004-2024. The quantity is highly variable, ranging from 1.1 to 2.6. Unsurprisingly, the highest values of risk aversion occurred at the peak of the Global Financial Crisis in March 2009 and at the beginning of the US COVID outbreak in March 2020.</p>

<h4 style={{textAlign: 'center'}}>Figure 3: Model Implied Risk Aversion</h4>
<img src={risk_aversion} alt="risk aversion" style={{ width: '100%'}}/>


<table style={{ width: '100%', borderCollapse: 'collapse' }}>
    <caption style={{ marginBottom: '10px' }}>
        Market-implied risk aversion statistics. 
        <div class='subcaption'>Assumes &#948; at 1% and &#963; at 17%, using S&amp;P 500 dividend yield and 30 year TIPS yield.</div>
    </caption>
    <thead>
        <tr>
            <th style={{ textAlign: 'left' }}>Statistic</th>
            <th style={{ textAlign: 'center' }}>Model</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>Mean</td>
            <td style={{ textAlign: 'center' }}>1.59</td>
        </tr>
        <tr>
            <td>Standard Deviation</td>
            <td style={{ textAlign: 'center' }}>0.23</td>
        </tr>
        <tr>
            <td>Minimum</td>
            <td style={{ textAlign: 'center' }}>1.14</td>
        </tr>
        <tr>
            <td>25th Percentile</td>
            <td style={{ textAlign: 'center' }}>1.38</td>
        </tr>
        <tr>
            <td>Median</td>
            <td style={{ textAlign: 'center' }}>1.59</td>
        </tr>
        <tr>
            <td>75th Percentile</td>
            <td style={{ textAlign: 'center' }}>1.74</td>
        </tr>
        <tr>
            <td>Maximum</td>
            <td style={{ textAlign: 'center' }}>2.58</td>
        </tr>
    </tbody>
</table>

<p>Acland and Greenberg conduct a meta analysis on the elasticity of marginal utility of income, and find an average value of 1.612 from 1,711 estimates across 158 studies. The table summarizing their findings is reproduced here.</p>

<table style={{ width: '100%', borderCollapse: 'collapse' }}>
    <caption style={{ marginBottom: '10px' }}>
        Weighted means of estimates of &#947; from Acland and Greenberg
    </caption>
    <thead>
        <tr>
            <th style={{ textAlign: 'left' }}>Category</th>
            <th style={{ textAlign: 'center' }}>Mean</th>
            <th style={{ textAlign: 'center' }}>s.e.</th>
            <th style={{ textAlign: 'center' }}>No. of estimates</th>
            <th style={{ textAlign: 'center' }}>No. of studies</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>All studies</td>
            <td style={{ textAlign: 'center' }}>1.612</td>
            <td style={{ textAlign: 'center' }}>0.221</td>
            <td style={{ textAlign: 'center' }}>1,711</td>
            <td style={{ textAlign: 'center' }}>158</td>
        </tr>
        <tr>
            <td>Excludes lowest and highest 10%</td>
            <td style={{ textAlign: 'center' }}>1.612</td>
            <td style={{ textAlign: 'center' }}>0.221</td>
            <td style={{ textAlign: 'center' }}>1,367</td>
            <td style={{ textAlign: 'center' }}>131</td>
        </tr>
        <tr>
            <td>Excludes lowest and highest 20%</td>
            <td style={{ textAlign: 'center' }}>1.676</td>
            <td style={{ textAlign: 'center' }}>0.269</td>
            <td style={{ textAlign: 'center' }}>1,026</td>
            <td style={{ textAlign: 'center' }}>108</td>
        </tr>
        <tr>
            <td>US studies</td>
            <td style={{ textAlign: 'center' }}>1.718</td>
            <td style={{ textAlign: 'center' }}>0.269</td>
            <td style={{ textAlign: 'center' }}>1,432</td>
            <td style={{ textAlign: 'center' }}>110</td>
        </tr>
        <tr>
            <td>UK studies</td>
            <td style={{ textAlign: 'center' }}>1.340</td>
            <td style={{ textAlign: 'center' }}>0.103</td>
            <td style={{ textAlign: 'center' }}>267</td>
            <td style={{ textAlign: 'center' }}>48</td>
        </tr>
        <tr>
            <td>Lifetime consumption studies</td>
            <td style={{ textAlign: 'center' }}>1.660</td>
            <td style={{ textAlign: 'center' }}>0.275</td>
            <td style={{ textAlign: 'center' }}>1,691</td>
            <td style={{ textAlign: 'center' }}>141</td>
        </tr>
        <tr>
            <td>Consumer demand studies</td>
            <td style={{ textAlign: 'center' }}>1.209</td>
            <td style={{ textAlign: 'center' }}>0.301</td>
            <td style={{ textAlign: 'center' }}>13</td>
            <td style={{ textAlign: 'center' }}>11</td>
        </tr>
        <tr>
            <td>All other studies</td>
            <td style={{ textAlign: 'center' }}>1.457</td>
            <td style={{ textAlign: 'center' }}>0.043</td>
            <td style={{ textAlign: 'center' }}>7</td>
            <td style={{ textAlign: 'center' }}>7</td>
        </tr>
        <tr>
            <td>Micro-data studies</td>
            <td style={{ textAlign: 'center' }}>1.779</td>
            <td style={{ textAlign: 'center' }}>0.127</td>
            <td style={{ textAlign: 'center' }}>482</td>
            <td style={{ textAlign: 'center' }}>44</td>
        </tr>
        <tr>
            <td>Aggregate data studies</td>
            <td style={{ textAlign: 'center' }}>1.547</td>
            <td style={{ textAlign: 'center' }}>0.256</td>
            <td style={{ textAlign: 'center' }}>1,229</td>
            <td style={{ textAlign: 'center' }}>115</td>
        </tr>
        <tr>
            <td>20th-century publication date</td>
            <td style={{ textAlign: 'center' }}>1.892</td>
            <td style={{ textAlign: 'center' }}>0.194</td>
            <td style={{ textAlign: 'center' }}>1,449</td>
            <td style={{ textAlign: 'center' }}>124</td>
        </tr>
        <tr>
            <td>21st-century publication date</td>
            <td style={{ textAlign: 'center' }}>1.112</td>
            <td style={{ textAlign: 'center' }}>0.192</td>
            <td style={{ textAlign: 'center' }}>262</td>
            <td style={{ textAlign: 'center' }}>35</td>
        </tr>
    </tbody>
</table>


<p>The estimate from Acland and Greenberg is remarkably close to the average model implied value (1.61 vs. 1.59), and the standard error of their estimate nearly identical to the standard deviation of the modeled value (0.22 vs. 0.23).</p>

<h4>Estimated values of the Equity Risk Premium</h4>
<p>The model implies an average equity risk premium of <InlineMath math={'4.60\\%'}/> during the period 2004-2024. For comparison, the realized equity risk premium vs. treasury bonds since 1900 is a nearly identical <InlineMath math={'4.7\\%'}/>(Credit Suisse Global Returns Yearbook for 2024).</p>
<p>While there are many sources of estimates of the equity risk premium, Aswath Damodaran regularly publishes a market-implied estimate, which has the benefit of being popular, market-derived, variable over time, available with historical data, and is calculated compared to long term bonds. Damodaran's estimate of equity risk premium is calculated using analyst estimates of growth rates and the S\&P 500 free cash flow to derive implied ERP estimates.The figure and chart below compare values. Damodaran's ERP averages <InlineMath math={'1\\%'}/> higher than the model implied estimate.</p>


<h4 style={{textAlign: 'center'}}>Figure 4: Market-implied long run equity risk premium. Model and Damodaran estimates</h4>
<img src={erp_compare} alt="erp" style={{ width: '100%'}}/>

<table style={{ width: '100%', borderCollapse: 'collapse' }}>
    <caption style={{ marginBottom: '10px' }}>
        Comparison of long run implied equity risk premium, 2005-2024.
        <div class='subcaption'> Model assumes &#963; at 17%, using S&amp;P 500 dividend yield and 30 year TIPS yield. "Damodaran" represents monthly estimates published by Aswath Damodaran through February 2024.</div>
    </caption>
    <thead>
        <tr>
            <th style={{ textAlign: 'left' }}>Statistic</th>
            <th style={{ textAlign: 'center' }}>Model</th>
            <th style={{ textAlign: 'center' }}>Damodaran</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>Mean</td>
            <td style={{ textAlign: 'center' }}>4.43%</td>
            <td style={{ textAlign: 'center' }}>5.42%</td>
        </tr>
        <tr>
            <td>Standard Deviation</td>
            <td style={{ textAlign: 'center' }}>0.71%</td>
            <td style={{ textAlign: 'center' }}>0.75%</td>
        </tr>
        <tr>
            <td>Minimum</td>
            <td style={{ textAlign: 'center' }}>3.11%</td>
            <td style={{ textAlign: 'center' }}>3.65%</td>
        </tr>
        <tr>
            <td>25th Percentile</td>
            <td style={{ textAlign: 'center' }}>3.76%</td>
            <td style={{ textAlign: 'center' }}>4.94%</td>
        </tr>
        <tr>
            <td>Median</td>
            <td style={{ textAlign: 'center' }}>4.49%</td>
            <td style={{ textAlign: 'center' }}>5.37%</td>
        </tr>
        <tr>
            <td>75th Percentile</td>
            <td style={{ textAlign: 'center' }}>4.88%</td>
            <td style={{ textAlign: 'center' }}>5.91%</td>
        </tr>
        <tr>
            <td>Maximum</td>
            <td style={{ textAlign: 'center' }}>7.28%</td>
            <td style={{ textAlign: 'center' }}>7.68%</td>
        </tr>
    </tbody>
</table>
<div id="appendix">
  <h3>Appendix</h3>
  <h2>Risk Free Rate</h2>
  <div>
    <p>The price of a zero coupon bond with one unit of consumption maturing at time s follows:</p>
    <BlockMath math={'P_t^0\\Lambda_t = E_t(\\Lambda_{t+s} 1)'}/>
    <p>Define the risk-free rate from t to s as the annualized negative log of the price of a unit of consumption at time s:</p>
    <BlockMath math={'r_t^f(s) := \\frac{-1}{s} \\log E_t\\left(\\frac{\\Lambda_{t+s}}{\\Lambda_t}\\right)'}/>
    
    <p>From the definition of utility,</p>
    <BlockMath math={'r_t^f(s) = \\frac{-1}{s} \\log E_t\\left(e^{-\\delta s} \\frac{C_{t+s}^{-\\gamma}}{C_t^{-\\gamma}}\\right)'}/>
    <p>Expressing in terms of log consumption <InlineMath math={'c_{t+s}'}/>,</p>
    <BlockMath math={'r_t^f(s) = \\frac{-1}{s} \\log E_t(e^{-\\delta s - \\gamma (c_{t+s} - c_t)})'}/>
    <p>Since log consumption is normally distributed, we can use</p>
    <BlockMath math={'E_t(e^{c_{t+s}}) = e^{E_t(c_{t+s}) + \\frac{\\text{var}(c_{t+s})}{2}}'}/>
    <BlockMath math={'r_t^f(s) = \\frac{-1}{s} \\left(-\\delta s - \\gamma E_t(c_{t+s} - c_t) + s\\gamma^2\\frac{\\sigma^2}{2}\\right)'}/>
    <p><InlineMath math={'(E_t(c_{t+s} - c_t)'}/> is simply gs. The s cancel, so the yield curve is flat and constant:</p>
    <BlockMath math={'r^f = \\delta + \\gamma g - \\gamma^2\\frac{\\sigma^2}{2}'}/>

  <h2>Dividend Yield</h2>

  <BlockMath math={"P_t \\Lambda_t=\\int_{s=0}^{\\infty}E_t(\\Lambda_{t+s} D_{t+s})ds"}/>
  <BlockMath math={"\\frac{P_t}{D_t} =\\int_{s=0}^{\\infty}E_t(\\frac{\\Lambda_{t+s}}{\\Lambda_t}\\frac{D_{t+s}}{D_{t}})ds"}/>
  <p>Rewriting using exponents and logarithms (where <InlineMath math={"\\lambda_t=log(\\Lambda_t)"}/> and <InlineMath math={"d_t=log(D_t)"}/>)</p>
  <BlockMath math={"\\frac{P_t}{D_t} =\\int_{s=0}^{\\infty}E_t(e^{\\lambda_{t+s}-\\lambda_t+d_{t+s}-d_{t}})ds"}/>
  <p>Since <InlineMath math={"d_t=c_t"}/> and <InlineMath math={"\\lambda_{t+s}=-\\delta s - \\gamma c_{t+s}"}/>,</p>
  <BlockMath math={"\\frac{P_t}{D_t} =\\int_{s=0}^{\\infty}E_t(e^{-\\delta s - \\gamma (c_{t+s}-c_t)+(c_{t+s}-c_{t})})ds"}/>
  <BlockMath math={"\\frac{P_t}{D_t} =\\int_{s=0}^{\\infty}E_t(e^{-\\delta s - (\\gamma-1) (c_{t+s}-c_t)})ds"}/>
  <p>Since consumption growth is lognormal,</p>
  <BlockMath math={"\\frac{P_t}{D_t} =\\int_{s=0}^{\\infty}e^{\\left(-\\delta  - (\\gamma-1) g+\\frac{(\\gamma-1)^2\\sigma^2}{2}\\right)s}ds"}/>
  <p>Integrating gives</p>
  <BlockMath math={"\\frac{P_t}{D_t} =\\frac{e^{\\left(-\\delta  - (\\gamma-1) g+\\frac{(\\gamma-1)^2\\sigma^2}{2}\\right)s}}{\\left(-\\delta  - (\\gamma-1) g+\\frac{(\\gamma-1)^2\\sigma^2}{2}\\right)}|_{s=0}^{\\infty}"}/>
  <p>Assuming <InlineMath math={"\\delta  + (\\gamma-1) g>\\frac{(\\gamma-1)^2\\sigma^2}{2}"}/>, the Price Dividend ratio expressed in terms of constants is:</p>
  <BlockMath math={"\\frac{P_t}{D_t} =\\frac{1}{\\delta  + (\\gamma-1) g-\\frac{(\\gamma-1)^2\\sigma^2}{2}}"}/>
  <p>Note that for <InlineMath math={"\\gamma>1"}/>, increased expected growth decreases the Price Dividend ratio. Expanding out terms allows us to substitute the risk free rate</p>
  <BlockMath math={"\\frac{P_t}{D_t} =\\frac{1}{\\delta  + \\gamma g-\\frac{\\gamma^2\\sigma^2}{2}+\\gamma\\sigma^2-\\frac{\\sigma^2}{2}-g}"}/>
  <BlockMath math={"\\frac{P_t}{D_t} =\\frac{1}{r^f+\\gamma\\sigma^2-(g+\\frac{\\sigma^2}{2})}"}/>
  <p>Dividend yield is therefore constant:</p>
  <BlockMath math={"\\frac{D}{P} =r^f+\\gamma\\sigma^2-(g+\\frac{\\sigma^2}{2})"}/>

  <h2>Risk Aversion</h2>

  <BlockMath math={"g= r^f+\\gamma\\sigma^2-\\frac{\\sigma^2}{2}-\\frac{D}{P}"}/>
  <BlockMath math={"r^f=\\delta  +\\gamma \\left(r^f+\\gamma\\sigma^2-\\frac{\\sigma^2}{2}-\\frac{D}{P}\\right)-\\gamma^2\\frac{\\sigma^2}{2}"}/>
  <BlockMath math={"0=\\delta-r^f  +\\gamma \\left(r^f-\\frac{\\sigma^2}{2}-\\frac{D}{P}\\right)+\\gamma^2\\frac{\\sigma^2}{2}"}/>
  <p>Keeping only the positive value of <InlineMath math={"\\gamma"}/> gives an equation for risk aversion in terms of dividend yield, risk free rate, volatility and time preference:</p>
  <BlockMath math={"\\gamma=\\frac{\\frac{D}{P}-r^f+\\frac{\\sigma^2}{2}+\\sqrt{\\left(\\frac{D}{P}-r^f+\\frac{\\sigma^2}{2}\\right)^2+2\\sigma^2(r^f-\\delta)}}{\\sigma^2}"}/>

  <h2>Growth of log consumption</h2>
  <p>Combining the solution for risk aversion with the equation for dividend yield gives a solution for growth in terms of dividend yield, risk free rate, volatility and time preference.</p>

  <BlockMath math={"g=\\sqrt{\\left(\\frac{D}{P}-r^f+\\frac{\\sigma^2}{2}\\right)^2+2\\sigma^2(r^f-\\delta)}"}/>


  <h2><InlineMath math={"\\delta"}/> bounds</h2>
  <p>Restrictions on quantities can be used to place upper and lower bounds on <InlineMath math={"\\delta"}/>.</p>
  <p>First, we assume that the representative agent does not prefer future utility to an equal amount of present utility, so <InlineMath math={"\\delta\\geq 0"}/>.</p>
  <p>Positive expected growth of log consumption implies:</p>

  <BlockMath math={"\\left(\\frac{D}{P}-r^f+\\frac{\\sigma^2}{2}\\right)^2+2\\sigma^2(r^f-\\delta)>0"}/>
  <BlockMath math={"\\delta<r^f+\\frac{\\left(\\frac{D}{P}-r^f+\\frac{\\sigma^2}{2}\\right)^2}{2\\sigma^2}"}/>
  <p>Positive risk aversion requires</p>
  <BlockMath math={"\\frac{D}{P}-r^f+\\frac{\\sigma^2}{2}+\\sqrt{\\left(\\frac{D}{P}-r^f+\\frac{\\sigma^2}{2}\\right)^2+2\\sigma^2(r^f-\\delta)}>0"}/>
  <BlockMath math={"\\frac{D}{P}-r^f+\\frac{\\sigma^2}{2}>-\\sqrt{\\left(\\frac{D}{P}-r^f+\\frac{\\sigma^2}{2}\\right)^2+2\\sigma^2(r^f-\\delta)}"}/>
  <p>If <InlineMath math={"\\frac{D}{P}-r^f+\\frac{\\sigma^2}{2}<0"}/></p>
  <BlockMath math={"\\left(\\frac{D}{P}-r^f+\\frac{\\sigma^2}{2}\\right)^2<\\left(\\frac{D}{P}-r^f+\\frac{\\sigma^2}{2}\\right)^2+2\\sigma^2(r^f-\\delta)"}/>
  <BlockMath math={"2\\sigma^2(\\delta-r^f)<0"}/>
  <BlockMath math={"\\delta<r^f"}/>
  <p>Combining these gives:</p>
  <BlockMath math={"0\\leq\\delta<r^f+\\frac{max\\left(\\frac{D}{P}-r^f+\\frac{\\sigma^2}{2},0\\right)^2}{2\\sigma^2}"}/>
  <h2>Expected Return</h2>
  <p>Define the annualized log return as the log change in wealth for an investor who is fully invested in the asset.</p>
  <BlockMath math={"r_{t\\rightarrow t+s}=\\frac{1}{s}log\\left(\\frac{V_{t+s}}{V_t}\\right)"}/>
  <p>The value of the investor's portfolio follows the process</p>
  <BlockMath math={"dV_t=V_t\\frac{D}{P}dt+V_t\\frac{dP_t}{P_t}"}/>
  <BlockMath math={"dV_t=V_t\\frac{D}{P}dt+V_t(\\frac{dD_t}{D_t})"}/>
  <BlockMath math={"dV_t=V_t\\frac{D}{P}dt+V_t(\\frac{(g+\\frac{\\sigma^2}{2})D_tdt+\\sigma D_t dW_t}{D_t})"}/>
  <BlockMath math={"dV_t=V_t\\left(\\frac{D}{P}+g+\\frac{\\sigma^2}{2}\\right) dt +\\sigma  V_tdW_t"}/>
  <p>From Ito's lemma</p>
  <BlockMath math={"dv_t=\\frac{D}{P}+g ~dt+\\sigma dW_t"}/>
  <BlockMath math={"v_{t+s}-v_t=\\int_{u=0}^s\\frac{D}{P}+g ~du+\\sigma\\int_0^s dW_t"}/>
  <BlockMath math={"v_{t+s}-v_t=\\left(\\frac{D}{P}+g\\right)s+\\sigma W(s)"}/>
  <BlockMath math={"E_t(r_{t \\rightarrow t+s})=\\frac{D}{P}+g"}/>
  <p>Replacing <InlineMath math={"\\frac{D}{P}"}/> gives:</p>
  <BlockMath math={"E_t(r_{t \\rightarrow t+s})=r^f+\\gamma\\sigma^2-\\frac{\\sigma^2}{2}"}/>
  <p>Using simple returns instead of logs gives the equity risk premium:</p>
  <BlockMath math={"E_t(R_{t \\rightarrow t+s})-r^f=\\gamma\\sigma^2"}/>
</div> {/*closes appendix*/}
</div>


</div>
        
  );
};

export default Theory;
