import React, { useEffect, useState } from 'react';
import Metric from './Metric';
import Mychart from './Mychart';
import { Link } from 'react-router-dom';


function parseER(csv) {
  const lines = csv.split('\n');
  const result = [];
  const headers = lines[0].split(',');
  const indexReal30 = headers.indexOf('real30');
  const indexErp = headers.indexOf('er');
  const indexBe30 = headers.indexOf('er_nom');

  lines.slice(1).forEach(line => {
    const cols = line.split(',');
    const date = cols[1]; // Date
    result.push({
      date,
      data: [parseFloat(cols[indexReal30]), parseFloat(cols[indexErp]), parseFloat(cols[indexBe30])]
    });
  });
  const colors = ['black', 'navy', 'lightblue']; // Colors for the datasets

  return { //this will get passed to createChart
    labels: result.map(row => row.date),
    datasets: ['real30', 'er', 'er_nom'].map((header, index) => ({
      label:['Real Risk Free Rate','Real Expected Equity Return','Nominal Expected Equity Return'][index],
      borderColor: colors[index],
      data: result.map(row => row.data[index]),
      fill: false, // Change to false for non-stacked line chart
      pointRadius: 0, // Hides the points on the line
      tension: 0.1,
    })),
  };
} 
function parseRiskAversion(csv) {
  const lines = csv.split('\n');
  const result = [];
  const headers = lines[0].split(',');
  //console.log(headers);
  const indexRa = headers.indexOf('risk_aversion');
  const indexErp = headers.indexOf('erp');

  lines.slice(1).forEach(line => {
    const cols = line.split(',');
    const date = cols[1]; // Date
    result.push({
      date,
      data: [parseFloat(cols[indexRa]), parseFloat(cols[indexErp])]
    });
  });
  const colors = ['navy', 'navy']; // Colors for the datasets

  return { //this will get passed to createChart
    labels: result.map(row => row.date),
    datasets: ['risk_aversion'].map((header, index) => ({
      label:['Risk Aversion'][index],
      borderColor: colors[index],
      data: result.map(row => row.data[index]),
      fill: false, // Change to false for non-stacked line chart
      pointRadius: 0, // Hides the points on the line
      tension: 0.1,
    })),
  };
} 

function parseGrowth(csv) {
  const lines = csv.split('\n');
  const result = [];
  const headers = lines[0].split(',');
  //console.log(headers);
  const indexGrowth = headers.indexOf('log_growth');

  lines.slice(1).forEach(line => {
    const cols = line.split(',');
    const date = cols[1]; // Date
    result.push({
      date,
      data: [parseFloat(cols[indexGrowth])]
    });
  });
  const colors = ['navy']; // Colors for the datasets

  return { //this will get passed to createChart
    labels: result.map(row => row.date),
    datasets: ['log_growth'].map((header, index) => ({
      label:['Expected Growth'][index],
      borderColor: colors[index],
      data: result.map(row => row.data[index]),
      fill: false, // Change to false for non-stacked line chart
      pointRadius: 0, // Hides the points on the line
      tension: 0.1,
    })),
  };
}







function Historicals(){

    const [erData, setErData] = useState(null);
    const [raData, setRaData] = useState(null);
    const [growthData, setGrowthData] = useState(null);
    const [currentData, setCurrentData] = useState(null);

    useEffect(() => {
      fetch('/historical_values.csv')
        .then(response => response.text())
        .then(csv => {
          // Call parseER and update erData state
            const erParsedData = parseER(csv);
            setErData(erParsedData);

            // Call parseRa and update raData state
            const raParsedData = parseRiskAversion(csv);
            setRaData(raParsedData);

            const growthParsedData = parseGrowth(csv);
            setGrowthData(growthParsedData);

      }).catch(error => console.error('Error:', error));
    }, []);

    useEffect(() => {
      fetch('/erp_latest.txt')
        .then(response => response.json())
        .then(data=>setCurrentData(data))
        .catch(error => console.error('Error:', error));
    }, []);

   console.log("Historicals");
   
    const er_nom=currentData?currentData['er_nom']:null;
    const er=currentData?currentData['er']:null;
    const erp=currentData?currentData['erp']:null;
    const risk_aversion=currentData?currentData['risk_aversion']:null;
    const log_growth=currentData?currentData['log_growth']:null;
    const last_time=currentData?currentData['erp'].last_time:null;


    // const metricLabels = {
    //     "er_nom": "Nominal Expected Equity Return",
    //     "er": "Real Expected Equity Return",
    //     "erp": "Equity Risk Premium",
    //     "risk_aversion": "Risk Aversion",
    //     "log_growth": "Expected Growth"
    // };


    return(
        <>
        <div className="metrics-wrapper">
            <div className="metrics-container">
                <Metric metricInfo={er_nom} label="Nominal Expected Equity Return" digits={2} />
                <Metric metricInfo={er}  label="Real Expected Equity Return" digits={2}/>
                <Metric metricInfo={erp} label="Equity Risk Premium" digits={2}/>
                <Metric metricInfo={risk_aversion} label="Risk Aversion" digits={2}/>
                <Metric metricInfo={log_growth} label="Expected Growth" digits={2}/>
            </div>
            
            <div id="lastUpdateTime">Last updated:{last_time} </div>
        </div>

        <br />
        <div className="notes">
            Data available in <a href="historical_values.csv">csv</a> format. Detailed background and economic model available in <Link to="/theory">theory</Link> section. 
            To understand the implication for equity allocations, see <Link to="/allocation">optimal equity allocation</Link> section. 
        </div>



        <br /><br />
        <Mychart title="Market Implied Expected Returns" chartinfo={erData} isPercentage={true} subtitle="% per year" notes="Long term expected returns. Real risk free rate based on 30 year TIPS yields. Equity risk premium based on iid lognormal consumption growth equal to dividend growth, with δ=1.0% and σ=17%, using dividend yield from the S&P 500. Nominal expected return equals equity risk premium plus 30 year treasury yields."/>
        <br /><br /><br />
        
        <Mychart title="Market Implied Risk Aversion" chartinfo={raData} isPercentage={false} subtitle="Power utility risk aversion coefficient" notes="Represents the price of risk: how much additional return investors demand for each additional unit of variance. Can be interpreted as the optimal equity allocation for an investor following the Kelly Criterion. Real risk free rate based on 30 year TIPS yields. Based on iid lognormal consumption growth equal to dividend growth, with δ=1.0% and σ=17%, using dividend yield from the S&P 500."/>
        <br /><br /><br />
        <Mychart title="Market Implied Expected Growth" chartinfo={growthData} isPercentage={true} subtitle="% per year" notes="Expected long term annual change of the logarithm of consumption, price and dividends. Real risk free rate based on 30 year TIPS yields. Based on iid lognormal consumption growth equal to dividend growth, with δ=1.0% and σ=17%, using dividend yield from the S&P 500."/>
        <br /><br />
        </>
    );
}
export default Historicals;