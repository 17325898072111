import React, { useState, useEffect, useCallback} from 'react';
//import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import HeaderSidebar from './HeaderSidebar';
import Sidebar from './Sidebar';
import Historicals from './Historicals';
import Theory from './Theory';
import OptimalAllocation from './OptimalAllocation';


import './style.css';  

function App() {

  return (
    <>
    <Router>
    <div>
      <HeaderSidebar/>
      <main>
        <Routes>
          <Route path="/" element={<Historicals/>} />
          <Route path="/historicals" element={<Historicals/>} />
          <Route path="/allocation" element={<OptimalAllocation />}/>
          <Route path="/theory" element={<Theory />}/>

        </Routes>
      </main>
    </div>
    </Router>
    </>
  );
}

export default App;
