import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';

function HeaderSidebar() {
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const handleResize = useCallback(() => {
    const mobile = window.innerWidth < 768;
    setIsMobile(mobile);

    if (mobile) {
      setIsSidebarVisible(false);
      setIsOverlayVisible(false);
    } else {
      setIsOverlayVisible(false);
      setIsSidebarVisible(true);
    }
  }, []);

  useEffect(() => {
    handleResize(); // Set initial state
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  const toggleSidebar = useCallback(() => {
    setIsSidebarVisible(prev => !prev);
    if (isMobile) {
      setIsOverlayVisible(prev => !prev);
    }
  }, [isMobile]);

  const closeSidebar = () => {
    if (isMobile) {
      setIsSidebarVisible(false);
      setIsOverlayVisible(false);
    }
  };

  const handleClickOutside = (event) => {
    if (window.innerWidth < 768) {
      const menuToggle = document.getElementById('menu-toggle');
      const isClickInsideMenuToggle = menuToggle && menuToggle.contains(event.target);
      const sidebar = document.getElementById('sidebar');
      const isClickInsideSidebar = sidebar && sidebar.contains(event.target);

      if (!isClickInsideSidebar && !isClickInsideMenuToggle && isSidebarVisible) {
        setIsSidebarVisible(false); // Close the sidebar
        setIsOverlayVisible(false); // Optionally hide the overlay as well
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [handleClickOutside]);

  return (
    <>
      <header>
          <div className="header-content">
              <button id="menu-toggle" onClick={toggleSidebar}>☰</button>
              <div className="logo">HendyStat</div>
              <div className="spacer"></div>
          </div>
      </header>
      <div className={`overlay ${isOverlayVisible ? 'active' : ''}`} onClick={closeSidebar}></div>
      <nav id="sidebar" style={{ left: isSidebarVisible ? '0px' : '-250px' }}>
          <ul>
              <li><Link to="/" onClick={closeSidebar}>Home</Link></li>
              <li><Link to="/historicals" onClick={closeSidebar}>Market-Implied Statistics</Link></li>
              <li><Link to="/allocation" onClick={closeSidebar}>Optimal Equity Allocation</Link></li>
              <li><Link to="/theory" onClick={closeSidebar}>Theory</Link></li>

          </ul>
      </nav>
    </>
  );
}

export default HeaderSidebar;
